<template>
	<div class="tabs row">
		<div class="col-auto col-md-2 col-12">
			<ul role="tablist" class="nav nav-pills flex-column nav-left">
				<li role="presentation" class="nav-item">
					<router-link exact :to="{ name: 'm&e' }" class="nav-link">
						<span class="font-weight-bold">Community Assists</span>
					</router-link>
				</li>
				<li role="presentation" class="nav-item">
					<router-link :to="{ name: 'm&e.beneficiaries' }" class="nav-link">
						<span class="font-weight-bold">Beneficiaries</span>
					</router-link>
				</li>
				<li role="presentation" class="nav-item">
					<router-link :to="{ name: 'm&e.gender' }" class="nav-link">
						<span class="font-weight-bold">Gender</span>
					</router-link>
				</li>
				<li role="presentation" class="nav-item">
					<router-link :to="{ name: 'm&e.indicators' }" class="nav-link">
						<span class="font-weight-bold">Indicators</span>
					</router-link>
				</li>
				<li role="presentation" class="nav-item">
					<router-link :to="{ name: 'm&e.maps' }" class="nav-link">
						<span class="font-weight-bold">Maps</span>
					</router-link>
				</li>
			</ul>
		</div>
		<div class="tab-content col col-12 col-md-10 mt-1 mt-md-0">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MAndEIndex',
};
</script>

<style lang="scss" scoped>
.nav-pills .router-link-active {
	border-color: #8bc53f;
	background-color: #8bc53f;
	box-shadow: 0 4px 18px -4px rgb(139 197 63 / 65%);
	color: #fff;
}
</style>
